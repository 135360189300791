import { FC } from 'react';

import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import GlobalSearchResultCard, {
  IGlobalSearchResultCardProps,
} from '@components/web/src/organisms/Cards/GlobalSearchResultCard/GlobalSearchResultCard';

export interface ISuggestionDataProps {
  characterName: string;
  productCategory: string;
  productsCount: number;
}

interface IProps {
  parsedSearchResultData: IGlobalSearchResultCardProps[];
  suggestionData: ISuggestionDataProps[];
  handleSuggestionBtnClick: (productCategory) => void;
}

const GlobalSearchResultTab: FC<IProps> = ({
  parsedSearchResultData = [],
  suggestionData,
  handleSuggestionBtnClick,
}) => {
  const { productCategoriesPlural } = localeCommon;
  const { suggestion, noMatchesFound, suggestionWithProductCategory } = localeApp.globalSearchNoResult;

  return (
    <div className="global-search-result-tab-container">
      {parsedSearchResultData.length ? (
        parsedSearchResultData.map(({ parsedProductInstanceData, handleCTAClick }, idx) => (
          <GlobalSearchResultCard
            key={idx}
            handleCTAClick={handleCTAClick}
            mixpanelIndex={idx}
            mixpanelPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
            parsedProductInstanceData={parsedProductInstanceData}
          />
        ))
      ) : (
        <div className="no-result-container">
          <p className="title">
            <LocaleFragment message={noMatchesFound} />
          </p>
          <p className="subtitle">
            <LocaleFragment message={suggestion} />
          </p>
          <div className="suggestion-wrapper">
            {suggestionData.map(({ productCategory, characterName, productsCount }) => {
              const productCategoryPluralText = productCategoriesPlural[productCategory];
              return (
                <button
                  key={productCategory}
                  className={`suggestion-button ${productCategory}`}
                  onClick={() => handleSuggestionBtnClick(productCategory)}
                >
                  <LocaleFragment
                    index={{ productCategory }}
                    message={suggestionWithProductCategory}
                    options={{ characterName }}
                    variables={{ productCategoryPluralText }}
                  />
                  <span className="count">{productsCount}</span>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalSearchResultTab;
