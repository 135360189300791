import { FC, useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { IProductFeedbackDataWithFullProductData } from '@lib/core/users/slices/productFeedback';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CATEGORY_COFFEE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import infoIcon from '@components/web/src/assets/legacy/b2b/icons/info_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import TastePathEnd from '@components/web/src/components/TastePath/TastePathEnd/TastePathEnd';
import TastePathProductItem from '@components/web/src/components/TastePath/TastePathProductItem/TastePathProductItem';
import Tooltip from '@components/web/src/components/Tooltip/Tooltip';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import { TJourneyStep, TastePathProgress } from '@components/web/src/organisms/TastePathProgress/TastePathProgress';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import * as S from '@components/web/src/pages/widget/TastePathPage/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  producerName?: string;
  parsedProductInstanceData: TParsedProductInstance;
  retailerName?: string;
  productCategory: TProductCategory;
  isTastePathProductsLoading: boolean;
  isTastePathDone: boolean;
  feedbackData: IProductFeedbackDataWithFullProductData[];
  tasteJourneyData: TJourneyStep[];
  nextTastePathCategory: string;
  lastUpdatedFeedbackId: string;
  discoveryQuiz: IDiscoveryQuiz;
  shouldHideTastePathTooltipModal: boolean;
  shouldDisplaySkipCategoryBtn: boolean;
  shouldHideWishlist: boolean;
  shouldHideComment: boolean;
  shouldHideFeedback: boolean;
  isShowThirdPartyAcceptance: boolean;
  shouldShowDownloadAppCard?: boolean;
  handleThirdPartyAcceptance: (value: boolean) => void;
  navigateToTastePathStartPage: () => void;
  handleSkipTastePathCategory: () => void;
  handleCloseTooltip: () => void;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
}

const TastePathPage: FC<IProps> = ({
  parsedProductInstanceData,
  retailerName,
  productCategory,
  isTastePathProductsLoading,
  isTastePathDone,
  tasteJourneyData,
  nextTastePathCategory,
  feedbackData,
  lastUpdatedFeedbackId,
  shouldHideTastePathTooltipModal,
  shouldDisplaySkipCategoryBtn = false,
  discoveryQuiz,
  isShowThirdPartyAcceptance,
  shouldShowDownloadAppCard,
  thirdPartyNewsletterTexts,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  handleThirdPartyAcceptance,
  navigateToTastePathStartPage,
  handleSkipTastePathCategory,
  handleCloseTooltip,
}) => {
  const { productCharacterTechnicalCaption, productCharacterType } = parsedProductInstanceData || {};
  const productStyle = productCharacterType?.identifier || '';

  const {
    tastePathPage,
    tastePathPage: { btnText, alreadyTasted, tooltipModalTitle, tooltipModalSubTitle },
  } = localeWidget;

  const { publishedTerms } = LocaleUtils;

  const buttonText = `${publishedTerms[btnText.id]} ${nextTastePathCategory}`;

  const randomDescriptionText = useMemo(() => {
    if (productStyle && productCategory && productCategory !== PRODUCT_CATEGORY_COFFEE) {
      const randomIndex = Math.floor(Math.random() * 3) + 1;
      return publishedTerms[tastePathPage[`description${productStyle}${productCategory}${randomIndex}`]?.id];
    }
    return '';
  }, [productStyle, parsedProductInstanceData, productCategory]);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (nextTastePathCategory) {
      setAnimate(true);
    }
  }, [nextTastePathCategory]);

  const cardAnimation = useSpring({
    config: {
      friction: 30,
      tension: 300,
    },
    onRest: () => setAnimate(false),
    opacity: animate ? 0 : 1,
    transform: `translateY(${animate ? 35 : 0}px)`,
  });

  // without delaying displaying the product we break animation
  const [delayedProduct, setDelayedProduct] = useState(parsedProductInstanceData);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (parsedProductInstanceData) {
        setDelayedProduct(parsedProductInstanceData);
      }
    }, 400);

    return () => clearTimeout(timer);
  }, [parsedProductInstanceData]);

  return (
    <S.TastePathPageContainer direction="column">
      {isTastePathDone ? (
        <TastePathEnd />
      ) : (
        <S.TastePathContent direction="column">
          <Flexbox align="center" justify="space-between">
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="h5"
              text={tastePathPage.title}
              weight="semibold"
            />
            <S.styledButton onClick={navigateToTastePathStartPage}>
              <Image alt="info icon" src={infoIcon} />
            </S.styledButton>
          </Flexbox>
          {tasteJourneyData && <TastePathProgress steps={tasteJourneyData} />}
          <S.DescriptionSection>
            <Text color={STATIC_COLORS.base.black} size="body1" text={randomDescriptionText} />
            <br />
            <Text
              color={STATIC_COLORS.productColors[productCategory][500]}
              size="body1"
              text={productCharacterTechnicalCaption}
              weight="medium"
            />
          </S.DescriptionSection>
          <animated.div style={cardAnimation}>
            <S.ProductCardSection>
              {!shouldHideTastePathTooltipModal && parsedProductInstanceData && (
                <Tooltip
                  className="taste-path-tooltip-modal"
                  description={tooltipModalSubTitle}
                  handleCloseClick={handleCloseTooltip}
                  title={tooltipModalTitle}
                />
              )}
              <ProductCard
                isResponsive
                discoveryQuiz={discoveryQuiz}
                isLoading={isTastePathProductsLoading}
                mixpanelPositionContext={MP_POSITION_CONTEXT.TASTE_PATH_PAGE}
                parsedProductInstanceData={delayedProduct}
                shouldHideComment={shouldHideComment}
                shouldHideFeedback={shouldHideFeedback}
                shouldHideWishlist={shouldHideWishlist}
                storeType="events"
              />
            </S.ProductCardSection>
          </animated.div>
          {shouldDisplaySkipCategoryBtn && (
            <Button
              size="sm"
              text={buttonText}
              variant={VH_VARIANTS.LIGHT}
              handleClick={() => {
                MixpanelTracker.events.skipCharacterType(productStyle);
                handleSkipTastePathCategory();
              }}
            />
          )}
        </S.TastePathContent>
      )}
      {isShowThirdPartyAcceptance && (
        <ThirdPartyNewsletter
          handleThirdPartyAcceptance={handleThirdPartyAcceptance}
          retailerName={retailerName}
          thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
          withMarginTop={shouldShowDownloadAppCard}
        />
      )}
      {!!feedbackData.length && (
        <S.PathProductsListSection direction="column" gap={8}>
          <Text color={STATIC_COLORS.green['600']} size="body1" text={alreadyTasted} weight="semibold" />
          {feedbackData.map(({ product }, idx) => (
            <TastePathProductItem
              key={idx}
              isFeedbackModalDisabled={isTastePathDone}
              isUpdatedItem={product.productId === lastUpdatedFeedbackId}
              parsedProductInstanceData={product}
            />
          ))}
        </S.PathProductsListSection>
      )}
    </S.TastePathPageContainer>
  );
};

export default TastePathPage;
