import { FC, SyntheticEvent, useState } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import { HARDCODED_FEEDBACK_DATA } from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import startIconDefault from '@components/web/src/assets/icons/feedback/feedback_start_icon.svg';
import startIconLight from '@components/web/src/assets/icons/feedback/feedback_start_icon_light.svg';
import FeedbackTooltip from '@components/web/src/atoms/Feedback/Feedback/FeedbackTooltip';
import * as S from '@components/web/src/atoms/Feedback/Feedback/styles';
import { Text } from '@components/web/src/foundations/Text/Text';
import DiscoveryQuizModal, {
  IDiscoveryQuiz,
} from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import FeedbackModal from '@components/web/src/templates/Modals/FeedbackModal/FeedbackModal';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  variant?: 'default' | 'light';
  discoveryQuiz: IDiscoveryQuiz;
  isFeedbackModalDisabled?: boolean;
  shouldShowFeedbackTooltip?: boolean;
  handleRedirectToRegistrationPage?: () => void;
}

const Feedback: FC<IProps> = ({
  parsedProductInstanceData,
  variant = 'default',
  discoveryQuiz,
  isFeedbackModalDisabled = false,
  shouldShowFeedbackTooltip,
}) => {
  const { feedback } = parsedProductInstanceData || {};

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isDiscoveryQuizModalOpen, setIsDiscoveryQuizModalOpen] = useState(false);

  const { productCard, tasteId } = localeCommon;
  const isLightVariant = variant === 'light';
  const startIcon = isLightVariant ? startIconLight : startIconDefault;
  const icon = feedback ? HARDCODED_FEEDBACK_DATA[feedback].icon.activeIcon : startIcon;

  const handleButtonClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsFeedbackModalOpen(true);
  };

  const isShowFeedbackTooltip = shouldShowFeedbackTooltip && !feedback;

  return (
    <>
      <S.FeedbackButton $isLightVariant={isLightVariant} onClick={handleButtonClick}>
        <S.RatingImage alt="feedback icon" src={icon} />
        {!feedback && <Text size="body3" text={productCard.rateIt} weight="medium" />}
        <FeedbackTooltip isAnimated infoText={tasteId.tasteIdTooltipText} isShowTooltip={isShowFeedbackTooltip} />
      </S.FeedbackButton>

      {isFeedbackModalOpen && parsedProductInstanceData && !isFeedbackModalDisabled && (
        <FeedbackModal
          handleOpenDiscoveryQuizModal={() => setIsDiscoveryQuizModalOpen(true)}
          hideModal={() => setIsFeedbackModalOpen(false)}
          isModalOpen={isFeedbackModalOpen}
          parsedProductInstanceData={parsedProductInstanceData}
        />
      )}
      {isDiscoveryQuizModalOpen && discoveryQuiz && (
        <DiscoveryQuizModal
          discoveryQuiz={discoveryQuiz}
          hideModal={() => setIsDiscoveryQuizModalOpen(false)}
          isModalOpen={isDiscoveryQuizModalOpen}
        />
      )}
    </>
  );
};

export default Feedback;
