import styled from 'styled-components';

export const PromotionBadgesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  height: 30px;
  width: 100%;
`;

export const PromotionIconWrapper = styled.div<{ $isLargeVariant: boolean }>`
  width: 50px;
  height: ${({ $isLargeVariant, theme }) => ($isLargeVariant && theme?.isKioskRootElementType ? '48px' : '30px')};

  & > div {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const DefaultPromotionIcon = styled.div<{ $color: string; $isLargeVariant: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
  height: 100%;
  background: ${({ $color }) => $color && `${$color}`};
  padding: ${({ $isLargeVariant, theme }) =>
    $isLargeVariant && theme?.isKioskRootElementType ? '4px 12px' : '8px 7px'};

  span {
    line-height: normal;
    font-size: ${({ $isLargeVariant, theme }) => ($isLargeVariant && theme?.isKioskRootElementType ? '14px' : '8px')};
  }
`;
