import { FC, useState } from 'react';

import ProductDetailsPdfContainer from '@app/web/src/containers/ProductDetailsPdfContainer';

import { TParsedProductInstance } from '@lib/core/products/types';
import { promotionsFilter } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT, MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PROMOTION_BADGE_FIDELITY,
  PROMOTION_BADGE_OTHER,
  PROMOTION_BADGE_PROMOTION,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';
import { parseTasteMatchLevel } from '@lib/tools/tasteMatch';
import { useAddons } from '@lib/tools/views/hooks';

import Button from '@components/web/src/atoms/Buttons/Button';
import Comment from '@components/web/src/atoms/Comment/Comment';
import Feedback from '@components/web/src/atoms/Feedback/Feedback/Feedback';
import PriceRange from '@components/web/src/atoms/PriceRange/PriceRange';
import TasteMatchButton from '@components/web/src/atoms/TasteMatchButton/TasteMatchButton';
import TooltipComponent from '@components/web/src/atoms/Tooltips/Tooltip';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import { IRecipe } from '@components/web/src/organisms/Cards/ChefRecommendsCard/ChefRecommendsCard';
import ReadMoreCard from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import CommentForProductCard from '@components/web/src/organisms/Comment/CommentForProductPage';
import * as S from '@components/web/src/organisms/ProductDetails/Web/styles';
import { PromotionBadges } from '@components/web/src/organisms/PromotionBadges/PromotionBadges';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  isShowPromoLabel?: boolean;
  shouldDisplayContactProducerBtn?: boolean;
  shouldHideWishlist?: boolean;
  shouldHideComment?: boolean;
  shouldHideFeedback?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  isUserHasAnyCharacter: boolean;
  isProductPDFDownload?: boolean;
  mixpanelPositionContext?: MixpanelPositionContext;
  navigateToQuizSelectionPage: () => void;
  isRecipesDataLoading?: boolean;
  recipesData?: IRecipe[];
}

const ProductDetails: FC<IProps> = ({
  parsedProductInstanceData,
  isShowPromoLabel = false,
  shouldDisplayContactProducerBtn = false,
  shouldHideComment = false,
  shouldHideFeedback = false,
  shouldHideWishlist = false,
  isProductPDFDownload = false,
  isUserHasAnyCharacter,
  mixpanelPositionContext = MP_POSITION_CONTEXT.PRODUCT_PAGE,
  discoveryQuiz,
  navigateToQuizSelectionPage,
  isRecipesDataLoading,
  recipesData,
}) => {
  const { productDetails: productDetailsMessages, commonMessages } = localeCommon;

  const {
    productId,
    productCategory,
    productCharacterName,
    productCharacterTechnicalCaption,
    productName,
    productImage,
    productStyle,
    productDescription,
    productTags,
    productFormat,
    productFormatSize,
    productFormatName,
    productCurrencySymbol,
    productProducerName,
    productProducerUrl,
    productOriginalPrice,
    productDiscountPrice,
    showedProductOriginalPrice,
    showedProductDiscountPrice,
    productRegionName,
    promotions,
    productRegionCountry,
    productTasteMatchValue,
    isFindProductButtonEnable,
    productLocationDescription,
    productLocationMap,
    productEcommerceId,
    isCommentInList,
    commentFromList,
    isCommentsDataLoading,
    handleSubmitComment,
    setIsNewCommentsDataFetching,
    isNewCommentsDataFetching,
    isWishlistProductListLoading,
    handleUpdateWishlistProductList,
    isProductInstanceInWishlist,
    isEnableLocationMapAddon,
    isEnableVusionAddon,
  } = parsedProductInstanceData || {};

  const { isPriceRangeAddon } = useAddons();
  const [isTooltipVisible, setTooltipVisible] = useState(true);
  const isProductRegionAvailable = !!productRegionCountry && !!productRegionName;

  const promotionBadges = promotionsFilter(promotions, [
    PROMOTION_BADGE_FIDELITY,
    PROMOTION_BADGE_PROMOTION,
    PROMOTION_BADGE_OTHER,
  ]);

  return (
    <S.ProductDetailsContainer>
      <S.ProductImageContainer>
        {isShowPromoLabel && (
          <S.TopPromoIcon>
            <S.PromoText size="body3" text={commonMessages.promo} weight="semibold" />
          </S.TopPromoIcon>
        )}
        <S.ProductImageWrapper>
          <Image alt="product" height="85%" objectFit="contain" src={productImage} />
        </S.ProductImageWrapper>
        <S.TagsWrapper>
          {productTags?.map(tag => <Image key={tag.tagName} alt={tag.tagName} src={tag.icon} />)}
        </S.TagsWrapper>
        <S.ImageCtaWrapper>
          <S.FeedbackWrapper align="center" direction="column" gap={16}>
            {!shouldHideWishlist && (
              <Wishlist
                isItemInWishlist={isProductInstanceInWishlist}
                isItemListLoading={isWishlistProductListLoading}
                handleUpdateWishlist={() => {
                  handleUpdateWishlistProductList(productId, productName);
                  MixpanelTracker.events.productBookmarkParsedProductData(
                    parsedProductInstanceData,
                    isProductInstanceInWishlist,
                    null,
                    mixpanelPositionContext,
                  );
                }}
              />
            )}
            {!shouldHideFeedback && (
              <Feedback discoveryQuiz={discoveryQuiz} parsedProductInstanceData={parsedProductInstanceData} />
            )}
            {!shouldHideComment && (
              <Comment
                commentFromList={commentFromList}
                handleSubmitComment={handleSubmitComment}
                isCommentInList={isCommentInList}
                isCommentsDataLoading={isCommentsDataLoading}
                isNewCommentsDataFetching={isNewCommentsDataFetching}
                setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
              />
            )}
          </S.FeedbackWrapper>
          {isProductPDFDownload && !!parsedProductInstanceData && (
            <ProductDetailsPdfContainer
              isRecipesDataLoading={isRecipesDataLoading}
              parsedProductInstanceData={parsedProductInstanceData}
              recipesData={recipesData}
            />
          )}
        </S.ImageCtaWrapper>
      </S.ProductImageContainer>
      <S.ProductContentContainer>
        {productProducerName && (
          <Text color={STATIC_COLORS.base.black} size="body1" text={productProducerName} weight="semibold" />
        )}
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          linesLimit={2}
          size="h4"
          text={productName}
          weight="semibold"
        />
        <Text
          color={STATIC_COLORS.base.black}
          size="body1"
          text={productStyle || (isProductRegionAvailable && `${productRegionName}, ${productRegionCountry}`) || ''}
        />
        <Text
          linesLimit={1}
          size="body2"
          text={`#${productCharacterName} • ${productCharacterTechnicalCaption}`}
          weight="medium"
        />
        <TooltipComponent
          isAlwaysOpen={isTooltipVisible}
          placement="top-end"
          title={productDetailsMessages.tasteMatchTooltip}
          trigger={
            <TasteMatchButton
              hideTooltip={() => setTooltipVisible(false)}
              navigateToQuizSelectionPage={navigateToQuizSelectionPage}
              parsedProductInstanceData={parsedProductInstanceData}
              tasteMatchLevel={parseTasteMatchLevel(productTasteMatchValue, isUserHasAnyCharacter)}
              tasteMatchValue={productTasteMatchValue}
            />
          }
        />
        {productDescription && (
          <S.DescriptionWrapper>
            <ReadMoreCard
              isTransparentVariant
              cardTitle={productDetailsMessages.descriptionTitle}
              cardTitleColor={STATIC_COLORS.green['600']}
              description={productDescription}
              minimumCharacters={190}
              handleClick={() =>
                MixpanelTracker.events.productStoryClick(
                  parsedProductInstanceData,
                  isProductInstanceInWishlist,
                  mixpanelPositionContext,
                )
              }
            />
          </S.DescriptionWrapper>
        )}
        {!shouldHideComment && (
          <CommentForProductCard
            commentFromList={commentFromList}
            handleSubmitComment={handleSubmitComment}
            isCommentInList={isCommentInList}
            isCommentsDataLoading={isCommentsDataLoading}
            isNewCommentsDataFetching={isNewCommentsDataFetching}
            setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
          />
        )}
        <S.PriceAndFormatWrapper>
          {productFormat && (
            <Text
              color={STATIC_COLORS.base.black}
              size="body2"
              text={`${productFormatName}${productFormatSize && `, ${productFormatSize}`}`}
            />
          )}
          {isPriceRangeAddon ? (
            <PriceRange
              currencySymbolValue={productCurrencySymbol}
              originalPrice={productOriginalPrice}
              productCategory={productCategory}
            />
          ) : (
            <S.PriceWrapper>
              {productDiscountPrice ? (
                <>
                  <S.PriceDashText size="subtitle1" text={showedProductOriginalPrice} />
                  <S.PriceText size="subtitle1" text={showedProductDiscountPrice} weight="semibold" />
                </>
              ) : (
                <S.PriceText size="subtitle1" text={showedProductOriginalPrice} weight="semibold" />
              )}
            </S.PriceWrapper>
          )}
        </S.PriceAndFormatWrapper>
        {!!promotionBadges?.length && <PromotionBadges isLargeVariant promotionsData={promotionBadges} />}
        <S.CtaWrapper>
          {isFindProductButtonEnable && !shouldDisplayContactProducerBtn && (
            <FindProductPopup
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              productEcommerceId={productEcommerceId}
              productLocationDescription={productLocationDescription}
              productLocationMap={productLocationMap}
            >
              <Button
                size="lg"
                text={productDetailsMessages.findItOnShelf}
                type="button"
                variant={VH_VARIANTS.PRIMARY}
                onClick={() =>
                  MixpanelTracker.events.findMe(
                    parsedProductInstanceData,
                    isProductInstanceInWishlist,
                    null,
                    mixpanelPositionContext,
                  )
                }
              />
            </FindProductPopup>
          )}
          {productProducerUrl && (
            <a {...(productProducerUrl ? { href: productProducerUrl, target: '_blank' } : {})}>
              <Button
                disabled={!productProducerUrl}
                handleClick={() => MixpanelTracker.events.contactTheProductProducer(parsedProductInstanceData)}
                size="lg"
                text={productDetailsMessages.contactProducerBtn}
                variant={VH_VARIANTS.PRIMARY}
              />
            </a>
          )}
        </S.CtaWrapper>
      </S.ProductContentContainer>
    </S.ProductDetailsContainer>
  );
};

export default ProductDetails;
