import { FC } from 'react';

import { IAuthFeatureParams } from '@app/web/src/hooks/useAuthFeatureClick';

import { AUTH_BANNERS } from '@lib/core/banners/consts';
import { TCharacter } from '@lib/core/characters/types';
import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';

import { ICharacterAdjectiveItemData } from '@components/web/src/atoms/CharacterAdjective/CharacterAdjective';
import { FooterAppAnonymous } from '@components/web/src/atoms/Footers';
import RegistrationBanner from '@components/web/src/components/Authentication/RegistrationBanner/RegistrationBanner';
import CharacterAdjectivesGroup from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';
import TastePathGuide from '@components/web/src/organisms/TastePathGuide/TastePathGuide';
import LearnMoreSection from '@components/web/src/organisms/TestResult/LearnMoreSection/LearnMoreSection';
import ProductSection from '@components/web/src/organisms/TestResult/ProductSection/ProductSection';
import QuizSection, { IQuizSectionQuizData } from '@components/web/src/organisms/TestResult/QuizSection/QuizSection';
import * as S from '@components/web/src/templates/ResultPages/TestResultPage/styles';

interface IProps {
  storeType: TRetailerLocationStoreType;
  parsedProductInstanceData: TParsedProductInstance;
  productCategory: TProductCategory;
  characterData: TCharacter;
  quizData: IQuizSectionQuizData[];
  characterAdjectiveItemsData: ICharacterAdjectiveItemData[];
  retailerLocationName?: string;
  isDesignSetVinhoodExperience?: boolean;
  isLoadingProduct?: boolean;
  isEnableTastePathAddon?: boolean;
  shouldHideComment: boolean;
  shouldHideFeedback: boolean;
  shouldHideWishlist: boolean;
  handleAuthFeatureClick?: (params?: IAuthFeatureParams) => void;
  handleProductCardClick?: () => void;
}

const TestResultPage: FC<IProps> = ({
  storeType,
  parsedProductInstanceData,
  characterData,
  quizData,
  productCategory,
  characterAdjectiveItemsData,
  retailerLocationName,
  isDesignSetVinhoodExperience = false,
  isLoadingProduct = false,
  isEnableTastePathAddon = false,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  handleAuthFeatureClick,
  handleProductCardClick,
}) => {
  return (
    <S.TestResultPageContainer direction="column">
      <CharacterAdjectivesGroup
        isTestResultPage
        characterAdjectiveItemsData={characterAdjectiveItemsData}
        characterData={characterData}
        handleAdjectiveClick={handleAuthFeatureClick}
        productCategory={productCategory}
      />
      {isEnableTastePathAddon ? (
        <TastePathGuide isResultPage characterName={characterData.name} />
      ) : (
        <>
          {(isLoadingProduct || !!parsedProductInstanceData) && (
            <ProductSection
              handleProductCardClick={handleProductCardClick}
              isLoadingProduct={isLoadingProduct}
              parsedProductInstanceData={parsedProductInstanceData}
              productCategory={productCategory}
              retailerLocationName={retailerLocationName}
              shouldHideComment={shouldHideComment}
              shouldHideFeedback={shouldHideFeedback}
              shouldHideWishlist={shouldHideWishlist}
              storeType={storeType}
            />
          )}
          <QuizSection
            characterData={characterData}
            handleQuizSectionClick={handleAuthFeatureClick}
            quizData={quizData}
          />
          <LearnMoreSection
            characterData={characterData}
            handleLearnMoreSectionClick={handleAuthFeatureClick}
            productCategory={productCategory}
          />
        </>
      )}
      {!isDesignSetVinhoodExperience && <FooterAppAnonymous showGearIcon />}
      <S.BannerWrapper>
        <RegistrationBanner
          handleNavigateToRegistrationPage={() =>
            handleAuthFeatureClick({ registrationBannerVariant: AUTH_BANNERS.click })
          }
        />
      </S.BannerWrapper>
    </S.TestResultPageContainer>
  );
};

export default TestResultPage;
