import { FC } from 'react';

import { IIdentitiesForWeb } from '@lib/core/products/hooks/useParsedProducts';
import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import BannerCard, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import ProductTags from '@components/web/src/components/Catalog/ProductTags/ProductTags';
import SectionHeader from '@components/web/src/components/SectionHeader/SectionHeader';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import EssenceCard, { IEssenceProps } from '@components/web/src/organisms/Cards/EssenceCard/EssenceCard';
import IdentityCard from '@components/web/src/organisms/Cards/IdentityCard/IdentityCard';

type Props = {
  productIdentitiesData: IIdentitiesForWeb;
  essencesData: IEssenceProps[];
  tagsData: string[];
  productCategory: TProductCategory;
  productDetailsBanners: TBannerCard[];
};

const Characteristics: FC<Props> = ({
  productDetailsBanners,
  productIdentitiesData,
  essencesData,
  tagsData,
  productCategory,
}) => {
  const { productCategories } = localeCommon;
  const { productPage } = localeApp;

  const { publishedTerms } = LocaleUtils;

  const productCategoriesText = publishedTerms[productCategories[productCategory].id];

  const { productIdentities, identityWarningText } = productIdentitiesData;

  return (
    <div className="characteristics-container">
      <div className="product-characteristics-page">
        <div className="product-characteristics-wrapper">
          <div className="product-essence-container">
            <SectionHeader
              sectionDescription={productPage.essenceCardSubtitle}
              sectionTitle={productPage.essenceCardTitle}
              titleLocaleOptions={{ productCategoriesText, productCategory }}
            />
            {essencesData && (
              <SwiperHOC className="essence-swipers">
                {essencesData.map(essence => (
                  <EssenceCard key={essence.identifier} productCategory={productCategory} {...essence} />
                ))}
              </SwiperHOC>
            )}
          </div>
          <div className="identity-card-container-wrapper">
            <SectionHeader
              sectionDescription={productPage.identityCardSubtitle}
              sectionTitle={productPage.identityCardTitle}
              descriptionLocaleOptions={{
                productCategoriesText,
                productCategory,
              }}
            />
            <IdentityCard identityWarningText={identityWarningText} productIdentities={productIdentities} />
          </div>
          {tagsData.length > 0 && (
            <div className="product-tags-container">
              <ProductTags tags={tagsData} />
            </div>
          )}
        </div>
      </div>
      <div className="product-banner-container">
        {productDetailsBanners?.map(banner => <BannerCard {...banner} key={banner.identifier} />)}
      </div>
    </div>
  );
};

export default Characteristics;
