import { FC } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { TProductCatalogOrdering } from '@lib/tools/filterManager/slices/productFilter';
import FilterContainer from '@lib/tools/filterManager/views/containers/FilterContainer';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { IFilterProps } from '@lib/tools/shared/helpers/interfaces';

import CatalogueResult from '@components/web/src/app/ProductCatalog/AppProductList/AppProductList';
import backArrowImg from '@components/web/src/assets/legacy/b2c/icons/back-arrow.svg';
import { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { ITab } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import Tabs from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import SortingSection from '@components/web/src/components/Catalog/SortingSection/SortingSection';
import MissedCharacterCard from '@components/web/src/organisms/Cards/MissedCharacterCard/MissedCharacterCard';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import CatalogSummary from '@components/web/src/pages/app/ProductCatalog/CatalogSummary/CatalogSummary';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

type IProductCatalogPageProps = {
  activeOrdering: TProductCatalogOrdering;
  banners: TBannerCard[];
  characterCaption?: string;
  filterProps: IFilterProps;
  isChangeOrderingEnabled?: boolean;
  isCharacterExistsForProductCategory: boolean;
  isFromSituationTest: boolean;
  isProductsRequestLoading: boolean;
  productCategory: TProductCategory;
  products: TParsedProductInstance[];
  swiperProducts?: TParsedProductInstance[];
  showFullCatalog?: boolean;
  storeType: TRetailerLocationStoreType;
  itemsCount: number;
  discoveryQuiz: IDiscoveryQuiz;
  getRandomBanner: () => TBannerCard;
  handleBackBtnClick?: () => void;
  handleChangeOrdering: (order: TProductCatalogOrdering) => void;
  handleChangeProductCategory?: (selectedProductCategory: TProductCategory) => void;
  handleBannerClick?: (route: string, linkParams: string) => void;
  handleGoBackFromSituationalContainer: () => void;
  handleProductsRequest: ({ isPagination }: { isPagination?: boolean }) => void;
  handleShowFullCatalogBannerClick?: () => void;
  navigateToFoodHabitsPage: () => void;
};

const ProductCatalogPage: FC<IProductCatalogPageProps> = ({
  activeOrdering,
  banners,
  characterCaption,
  filterProps,
  isChangeOrderingEnabled,
  isCharacterExistsForProductCategory,
  isFromSituationTest,
  isProductsRequestLoading,
  itemsCount,
  productCategory,
  products,
  swiperProducts,
  showFullCatalog,
  storeType,
  discoveryQuiz,
  getRandomBanner,
  handleBackBtnClick,
  handleChangeOrdering,
  handleChangeProductCategory,
  handleBannerClick,
  handleGoBackFromSituationalContainer,
  handleProductsRequest,
  handleShowFullCatalogBannerClick,
  navigateToFoodHabitsPage,
}) => {
  const { isFilterOpened } = useCatalogFilter();
  const { productCatalogPage } = localeApp;

  const {
    backToCatalog,
    catalogPageTitleSituational,
    catalogPageTitle: catalogPageTitleText,
    productTabWineTitle,
    productTabBeerTitle,
    productTabCoffeeTitle,
  } = productCatalogPage;

  const catalogPageTitle = isFromSituationTest ? catalogPageTitleSituational : catalogPageTitleText;

  const isAvailableProductsForFullCatalog = !isFilterOpened && !!products.length;

  const isNoAvailableProductsForFullCatalog = !isFilterOpened && !isProductsRequestLoading && !products.length;

  const tabsData: ITab[] = [
    {
      tabCode: PRODUCT_CATEGORY_WINE,
      tabIcon: PRODUCT_CATEGORY_WINE,
      tabText: productTabWineTitle,
    },
    {
      tabCode: PRODUCT_CATEGORY_BEER,
      tabIcon: PRODUCT_CATEGORY_BEER,
      tabText: productTabBeerTitle,
    },
    {
      tabCode: PRODUCT_CATEGORY_COFFEE,
      tabIcon: PRODUCT_CATEGORY_COFFEE,
      tabText: productTabCoffeeTitle,
    },
  ];

  return (
    <div className="product-catalog-page-container">
      {!showFullCatalog && (
        <div className="catalog-page-header">
          {isFromSituationTest && (
            <div
              className="catalog-page-back-button"
              role="button"
              tabIndex={-1}
              onClick={handleGoBackFromSituationalContainer}
              onKeyDown={handleGoBackFromSituationalContainer}
            >
              <img alt="goBack" src={backArrowImg} />
              <span className="back-button-title">
                <LocaleFragment message={backToCatalog} />
              </span>
            </div>
          )}
          <span className="header-title">
            <LocaleFragment message={catalogPageTitle} />
          </span>
        </div>
      )}
      <div className="catalog-tabs-wrapper">
        {!isFromSituationTest && !isFilterOpened && (
          <>
            <Tabs
              active={productCategory}
              tabs={tabsData}
              tabsVariant="autoSpaced"
              onTabClick={handleChangeProductCategory}
            />
            {showFullCatalog && <BackButton handleClick={handleBackBtnClick} />}
          </>
        )}
        {showFullCatalog && (
          <>
            <FilterContainer handleApply={handleProductsRequest} productCategory={productCategory} {...filterProps} />
            {isAvailableProductsForFullCatalog && (
              <>
                <SortingSection
                  activeOrdering={activeOrdering}
                  handleChangeOrdering={handleChangeOrdering}
                  isChangeOrderingEnabled={isChangeOrderingEnabled}
                  isProductsRequestLoading={isProductsRequestLoading}
                  itemsCount={itemsCount}
                />
                <CatalogueResult
                  banners={banners}
                  discoveryQuiz={discoveryQuiz}
                  getRandomBanner={getRandomBanner}
                  handleBannerClick={handleBannerClick}
                  handleProductsRequest={handleProductsRequest}
                  isProductsRequestLoading={isProductsRequestLoading}
                  itemsCount={itemsCount}
                  products={products}
                  storeType={storeType}
                />
              </>
            )}
            {isNoAvailableProductsForFullCatalog && <NoProductsAvailable />}
          </>
        )}
        {!showFullCatalog &&
          (isCharacterExistsForProductCategory ? (
            <CatalogSummary
              characterCaption={characterCaption}
              discoveryQuiz={discoveryQuiz}
              handleRedirectClick={handleShowFullCatalogBannerClick}
              productCategory={productCategory}
              swiperProducts={swiperProducts}
            />
          ) : (
            <div className="missed-character-card-container">
              <MissedCharacterCard handleButtonClick={navigateToFoodHabitsPage} productCategory={productCategory} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductCatalogPage;
