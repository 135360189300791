import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ACTIVE_PANEL_FILTER_URL_PARAM } from '@app/web/src/helpers/consts';

import { TJournalProductPanelFilter } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { IS_FROM_EXPLORE_PAGE } from '@lib/core/service/consts';
import { useScrollToTop } from '@lib/core/service/hooks/useScrollToTop';
import { prependBasename } from '@lib/core/service/utils';
import { useLocationList, useLocationLog } from '@lib/core/users/hooks';
import { removeUrlParams, setUrlParams } from '@lib/tools/shared/helpers';
import {
  JOURNAL_PANEL_PLACES_FILTER,
  JOURNAL_PLACES_VARIANT,
  PRODUCT_CATEGORY_URL_PARAM,
  TJournalPlacesPanelFilter,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import JournalPage from '@components/web/src/templates/TasteId/TasteIdJournalPage/TasteIdJournalPage';

const JournalPlacesContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { locationLogData } = useLocationLog();

  const { retailerStoreType } = useRetailerLocation();
  const { locationList, isLocationListLoading, handleUpdateLocationList } = useLocationList();

  const productCategoryUrlParam = searchParams.get(PRODUCT_CATEGORY_URL_PARAM);
  const isFromExplorePage = searchParams.get(IS_FROM_EXPLORE_PAGE) === 'true';
  const activePanelFilterFromUrl = searchParams.get(ACTIVE_PANEL_FILTER_URL_PARAM);
  const [activeJournalPanelFilter, setActivePanelFilter] = useState<TJournalPlacesPanelFilter>(
    (activePanelFilterFromUrl as TJournalPlacesPanelFilter) || JOURNAL_PANEL_PLACES_FILTER,
  );
  useScrollToTop(activeJournalPanelFilter);

  const handlePlacesPanelFilterChange = (value: TJournalPlacesPanelFilter) => {
    setActivePanelFilter(value);
    setUrlParams([{ key: ACTIVE_PANEL_FILTER_URL_PARAM, value }]);
  };

  const handlePanelFilterChange = (value: TJournalProductPanelFilter | TJournalPlacesPanelFilter) => {
    handlePlacesPanelFilterChange(value as TJournalPlacesPanelFilter);
  };

  useEffect(() => {
    if (isFromExplorePage) {
      removeUrlParams({
        keys: [IS_FROM_EXPLORE_PAGE, ACTIVE_PANEL_FILTER_URL_PARAM],
      });
    }
  }, []);

  const handleBackBtnClick = () =>
    navigate(prependBasename(PAGES.vinhood.tasteId.tasteId, { [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam }));

  const navigateToExplorePage = () => navigate(prependBasename(PAGES.vinhood.explore));

  const items = activeJournalPanelFilter === JOURNAL_PANEL_PLACES_FILTER ? locationList : locationLogData;

  return (
    <JournalPage
      activeJournalPanelFilter={activeJournalPanelFilter}
      handleBackBtnClick={handleBackBtnClick}
      handlePanelFilterChange={handlePanelFilterChange}
      handleUpdateLocationList={handleUpdateLocationList}
      isItemsLoading={false}
      isLocationListLoading={isLocationListLoading}
      items={items}
      itemsCount={items.length}
      navigateToExplorePage={navigateToExplorePage}
      productCategory={productCategoryUrlParam}
      storeType={retailerStoreType}
      variant={JOURNAL_PLACES_VARIANT}
    />
  );
};

export default JournalPlacesContainer;
