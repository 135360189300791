import { css, styled } from 'styled-components';

import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { SCREEN_SIZE, STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const EventsModalContainer = styled.div<{ $modalType?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 16px 24px;
  background: ${STATIC_GRADIENTS.warmGradientReversed()};
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 16px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 30%);
  box-shadow: ${STATIC_SHADOWS.xl};

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      padding: 40px 40px 24px;
    `};

  ${({ $modalType, theme }) => {
    if ($modalType === 'findMeModal') {
      return css`
        padding: 0;
        margin: 0 auto;
      `;
    }

    if ($modalType === 'locationMapModal' && theme?.isKioskRootElementType) {
      return css`
        padding-inline: 40px;
      `;
    }
    return css``;
  }}

  .qrcode {
    width: 200px;
    height: 200px;
    margin: 32px auto 0;
  }

  .modal-close-btn {
    margin-top: 24px;
    align-self: center;
    min-width: ${({ theme }) => (theme?.isKioskRootElementType ? '161px' : '150px')};
  }

  .content-wrapper {
    width: 100%;

    .btn-wrapper {
      width: 100%;
      padding: 24px 10px 16px;

      .find-it-btn {
        min-width: ${({ theme }) => (theme?.isKioskRootElementType ? '161px' : '150px')};
        padding: 10px 14px;
        margin: 0 auto;
      }
    }
  }

  .modal-icon {
    margin: 0 auto;
    margin-bottom: 4px;

    &.location-icon {
      width: 80px;
      height: 80px;
      margin-bottom: 12px;

      ${({ theme }) =>
        theme?.isKioskRootElementType &&
        css`
          width: 120px;
          height: 120px;
        `}
    }

    &.bottles-icon {
      margin-bottom: 32px;
    }
  }
`;

export const ModalCloseButton = styled(CloseButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const ProductLocationMapWrapper = styled(Flexbox)`
  padding-block: 16px;
  border-radius: 40px;
  background: ${STATIC_COLORS.base.white};

  img {
    width: 253px;
    height: 450px;

    @media screen and (min-width: ${SCREEN_SIZE.medium}) {
      width: 365px;
      height: 650px;
      margin: 0 120.12px 0 120.13px;
    }

    ${({ theme }) =>
      theme?.isKioskRootElementType &&
      css`
        width: 562px !important;
        height: 1000px !important;
      `}
  }
`;

export const CreditTitleText = styled(Text)`
  width: 100%;
  margin-bottom: 8px;
  text-align: left;
`;

export const TitleText = styled(Text)<{ $isLeftAlignedGreen?: boolean; $noMargin?: boolean }>`
  margin-bottom: 8px;
  color: ${STATIC_COLORS.base.black};
  text-align: center;

  ${({ $isLeftAlignedGreen }) =>
    $isLeftAlignedGreen &&
    css`
      width: 100%;
      margin-bottom: 8px;
      color: ${STATIC_COLORS.green['600']};
      text-align: left;
    `}

  ${({ $noMargin }) =>
    $noMargin &&
    css`
      margin-bottom: 0;
    `}
`;

export const DescriptionText = styled(Text)<{ $isLeftAlignedDescription?: boolean; $isLocationMap?: boolean }>`
  width: 100%;
  color: ${STATIC_COLORS.gray['900']};
  text-align: center;

  ${({ $isLeftAlignedDescription }) =>
    $isLeftAlignedDescription &&
    css`
      text-align: left;
    `}

  ${({ $isLocationMap }) =>
    $isLocationMap &&
    css`
      margin-bottom: 8px;
    `}
`;
export const ContentHeaderSection = styled.div`
  position: static !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  background-color: #f39c9c;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .icon {
    margin: 10px 24px 0 0;

    svg {
      width: 120px;
      height: 116px;
    }
  }
`;

export const ContentBodySection = styled(Flexbox)`
  padding: 16px 16px 0;
`;

export const BodyTextTitle = styled(Text)`
  margin: 0;
  color: ${STATIC_COLORS.base.black};
  text-align: center;
`;
