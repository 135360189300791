import { FC, useState } from 'react';

import { useUser } from '@lib/core/users/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import { ITab } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import Tabs from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

import TasteIdPageContainer from 'containers/TasteId/TasteIdPageContainer';
import TasteIdSettingsPageContainer from 'containers/TasteId/TasteIdSettingsPageContainer';
import useTasteIdData from 'hooks/useTasteIdData';

const TasteIdContainer: FC = () => {
  const [activeTab, setActiveTab] = useState('tasteIdPage');
  const { tasteIdText, settingsText } = localeCommon.tasteIdTabs;

  const { isTasteIdDataLoading } = useTasteIdData();
  const { isUploadingProfilePicture } = useUser();
  const isLoading = isTasteIdDataLoading || isUploadingProfilePicture;

  const handleTabClick = (tabCode: string) => {
    setActiveTab(tabCode);
  };

  const tabsData: ITab[] = [
    {
      children: <TasteIdPageContainer />,
      tabCode: 'tasteIdPage',
      tabText: tasteIdText,
    },
    {
      children: <TasteIdSettingsPageContainer />,
      tabCode: 'settingsPage',
      tabText: settingsText,
    },
  ];

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Tabs
      active={activeTab}
      customZIndex={4}
      gradientVariant="custom-down"
      tabs={tabsData}
      tabsVariant="fullSize"
      onTabClick={handleTabClick}
    />
  );
};

export default TasteIdContainer;
