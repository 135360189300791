import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';

import { TJournalProductPanelFilter, TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { FILTER_TYPE_RATING, PRODUCT_CARD_VARIANTS, TJournalPlacesPanelFilter } from '@lib/tools/shared/helpers/consts';

import * as S from '@components/web/src/app/Journal/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { DESKTOP_RESOLUTION_MIN_VALUE } from '@components/web/src/foundations/consts';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import EmptyStateCard from '@components/web/src/organisms/EmptyStateCard/EmptyStateCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  isProductsRequestLoading: boolean;
  itemsCount: number;
  products: TParsedProductInstance[];
  isAllProductsLoaded?: boolean;
  storeType: TRetailerLocationStoreType;
  discoveryQuiz: IDiscoveryQuiz;
  productCategory: TProductCategory;
  activeJournalPanelFilter: TJournalProductPanelFilter | TJournalPlacesPanelFilter;
  shouldHideComment?: boolean;
  navigateToCatalogPage: () => void;
  handleProductsRequest: ({ isPagination }: { isPagination?: boolean }) => void;
}

const JournalProductsList: FC<IProps> = ({
  isAllProductsLoaded,
  isProductsRequestLoading,
  itemsCount,
  products,
  storeType,
  discoveryQuiz,
  productCategory,
  activeJournalPanelFilter,
  shouldHideComment,
  navigateToCatalogPage,
  handleProductsRequest,
}) => {
  const {
    journalPage: { isEndOfProductsList },
    emptyStateCards: { noRatedProductTitle, noSavedProductTitle },
  } = localeCommon;
  const loader = (
    <S.SkeletonsWrapper>
      <ProductCard key={1} isLoading isResponsive storeType={storeType} />
      <ProductCard key={2} isLoading isResponsive storeType={storeType} />
    </S.SkeletonsWrapper>
  );
  const isDesktopResolution = useMediaQuery({ minWidth: DESKTOP_RESOLUTION_MIN_VALUE });

  const isEndOfProductList = isAllProductsLoaded && !!products.length;
  const isNoAvailableProducts = isAllProductsLoaded && !products.length;
  const isProductsLoading = !products.length || isProductsRequestLoading;
  const noProductsTitle = activeJournalPanelFilter === FILTER_TYPE_RATING ? noRatedProductTitle : noSavedProductTitle;

  return (
    <S.JournalProductListWrapper>
      <InfiniteScroll
        dataLength={products.length}
        hasMore={products.length < itemsCount}
        loader={loader}
        next={isProductsRequestLoading && !products.length ? null : () => handleProductsRequest({ isPagination: true })}
        scrollableTarget={isDesktopResolution ? 'root-scroll-element' : null}
      >
        {isProductsLoading && loader}
        {!isProductsLoading &&
          products?.map((parsedProductInstanceData, index) => (
            <ProductCard
              key={parsedProductInstanceData.productInstanceId}
              isResponsive
              discoveryQuiz={discoveryQuiz}
              isLoading={false}
              mixpanelIndex={index}
              mixpanelPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
              parsedProductInstanceData={parsedProductInstanceData}
              shouldHideComment={shouldHideComment}
              storeType={storeType}
              variant={PRODUCT_CARD_VARIANTS.COMPACT}
            />
          ))}
        {isNoAvailableProducts && (
          <S.NoProductsWrapper>
            <EmptyStateCard
              handleBtnClick={navigateToCatalogPage}
              productCategory={productCategory}
              title={noProductsTitle}
              variant="product"
            />
          </S.NoProductsWrapper>
        )}
        {isEndOfProductList && (
          <S.EndOfList>
            <Text color={STATIC_COLORS.gray['400']} size="body1" text={isEndOfProductsList} />
          </S.EndOfList>
        )}
      </InfiniteScroll>
    </S.JournalProductListWrapper>
  );
};

export { JournalProductsList };
