import { TProductInstance, TProductLocationPromotion } from '@lib/core/products/types';
import {
  selectAssociatedRetailerLocation,
  selectRetailerLocationId,
  selectRetailerLocationSlug,
} from '@lib/core/retailers/selectors/retailerLocation';
import {
  ITEM_ID_REPLACEMENT,
  REQUEST_ERROR_FORBIDDEN_MESSAGE,
  RETAILER_LOCATION_SLUG_FOR_REPLACEMENT,
  RETAILER_SLUG_FOR_REPLACEMENT,
  STORE_ID_REPLACEMENT,
} from '@lib/core/service/consts';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { selectRouteBasename } from '@lib/core/service/selectors/routes';
import { store } from '@lib/core/service/store';
import { history } from '@lib/core/service/utils/Navigator';
import { selectBestMatchCharacterForServiceProductCategory } from '@lib/core/users/selectors/characters';
import { selectFidelityCardId } from '@lib/core/users/selectors/fidelityCard';
import { TProductFeedback } from '@lib/core/users/slices/productFeedback';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import RouteUtils from '@lib/tools/routes';
import {
  CHARACTERS_URL_PARAM,
  FIDELITY_ID_URL_PARAM,
  IS_FROM_KIOSK_QR_URL_PARAM,
  STYLE_NAME,
  TASTE_PATH_PRODUCT_STYLES,
} from '@lib/tools/shared/helpers/consts';
import { ITastePathData } from '@lib/tools/tastePathProducts/types/types';
import { PAGES } from '@lib/tools/views/urls';

import { TJourneyStep } from '@components/web/src/organisms/TastePathProgress/TastePathProgress';
import { SLOW_PAGE_SCENARIOS } from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

export const uuidv4 = (): string => {
  return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c: any) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};

/**
 * Returns a boolean true if the app is mounted inside an iframe
 */
export const isAppInIframe = window.self !== window.top;

// ? Improve core navigator when react-router fixes nav bugs
export const prependBasename = (page, params = {}): string => {
  const baseUrl = selectRouteBasename(store.getState());
  const searchParams = new URLSearchParams(params).toString();
  const paramsString = searchParams ? `?${searchParams}` : '';

  return baseUrl + page + paramsString;
};

/**
 * @deprecated
 */
export const createBackendRetailerUrl = (backendUrl, retailerSlug) =>
  backendUrl.replace(RETAILER_SLUG_FOR_REPLACEMENT, retailerSlug);

/**
 * @deprecated
 */
export const createBackendRetailerLocationSlug = (backendUrl, retailerLocationSlug) =>
  backendUrl.replace(RETAILER_LOCATION_SLUG_FOR_REPLACEMENT, retailerLocationSlug);

/**
 * @deprecated
 */
export const createBackendFlashLabelUrl = (backendUrl, retailerSlug, storeId, itemId) =>
  backendUrl
    .replace(RETAILER_SLUG_FOR_REPLACEMENT, retailerSlug)
    .replace(STORE_ID_REPLACEMENT, storeId)
    .replace(ITEM_ID_REPLACEMENT, itemId);

// ToDo Check this
// ! Move into request.ts
export const checkStatus = (response, withErrorReturn = false) => {
  if (
    (response.status >= 200 && response.status < 300) ||
    (response?.config?.url === backendApiUrls.loginUrl && response.status === 401)
  ) {
    return response;
  }

  if (response.status === 403) {
    if (response?.data?.detail === REQUEST_ERROR_FORBIDDEN_MESSAGE) {
      history.push(prependBasename(PAGES.error.forbidden));
    }
    const error = new Error(response?.statusText);
    if (withErrorReturn) {
      return error;
    }
    throw error;
  }

  if (response.status >= 500) {
    history.push(prependBasename(PAGES.error.badRequest));
  }

  // We handle 401s for authenticated requests in request.js
  // @todo look into the double dispatches for fetchGetingSelectedRetailerLocation. We need to handle this better.
  // if (response.status === 401) { store.dispatch(actionResetAuthSlice(true)); }

  console.error('ERR', response);
  const error = new Error(response?.statusText);
  if (withErrorReturn) {
    return error;
  }
  throw error;
};

export function stringToBoolean(string) {
  return (
    string !== 'false' &&
    string !== 'undefined' &&
    string !== '0' &&
    string !== 'NaN' &&
    string !== 'null' &&
    string !== undefined &&
    !!string.replace(/\s/g, '').length
  );
}

export const capitalizeFirstLetter = string => (string ? string.charAt(0)?.toUpperCase() + string.slice(1) : '');

export const isTouchDevice = (): boolean =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  // @ts-ignore
  navigator.msMaxTouchPoints > 0;

export const getVinhoodTagsFromCharacteristics = characteristics => {
  if (characteristics) {
    const vinhoodTagsArray = [];

    characteristics.forEach(({ image, name }) => {
      let icon = '';

      if (image && image.trim() !== '') {
        icon = image;
      } else {
        try {
          icon = require(`media/images/vinhood/b2c/tags/tag_${name?.toLowerCase()}.svg`);
        } catch (error) {
          icon = '';
        }
      }
      vinhoodTagsArray.push({ icon, tagName: name });
    });
    return vinhoodTagsArray;
  }
  return null;
};

export const convertImageFiletoBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = err => reject(err);
  });

export const getMultipleUniqueRandomItemsFromArray = (arr = [], count) => {
  if (arr.length < 1) return [];
  const shuffled = [...arr].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, count);
};

export const getRandomItemFromArray = (array: any[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const shuffleArray = array => [...array].sort(() => Math.random() - 0.5);

export const shuffleProductsInsideRankGroups = (array: TProductInstance[]): TProductInstance[] => {
  const resultArray = [];
  const alreadyShuffledRanks = [];
  array?.forEach(product => {
    if (!alreadyShuffledRanks.includes(product.rank)) {
      const newRankPart = array.filter(({ rank }) => rank === product.rank);
      resultArray.push(...shuffleArray(newRankPart));
      alreadyShuffledRanks.push(product.rank);
    }
  });
  return resultArray;
};

export const insertIntlJSXPart = ({ text, linkId, linkRender, className = '' }) => {
  const [initialPart = '', linkPart = '', finalPart = ''] = text ? text.split(`[linkTo=${linkId}]`) : '';

  return (
    <div className={`insert-intl-jsx-wrapper ${className}`}>
      <LocaleFragment message={initialPart} />
      {initialPart && `${' '}`}
      {linkRender(linkPart)}
      {finalPart && `${' '}`}
      <LocaleFragment message={finalPart} />
    </div>
  );
};

// later it will be moved to RouteUtils
export const createMobileKioskQRUrl = () => {
  const { origin: originalOrigin, pathname, search } = window.location;
  const state = store.getState();
  const retailerLocationId = selectRetailerLocationId(state);
  const retailerLocationIdForReplacement = selectAssociatedRetailerLocation(state)?.identifier;
  const userCharacterIdentifier = selectBestMatchCharacterForServiceProductCategory(state)?.identifier;

  const fidelityCardId = selectFidelityCardId(state);

  const origin = originalOrigin.replace('kiosk', 'app');

  const mobileKioskPathname = pathname.replace(retailerLocationId, retailerLocationIdForReplacement);

  const qrCodeUrl = new URL(origin + mobileKioskPathname);

  const queryParams = {
    [CHARACTERS_URL_PARAM]: userCharacterIdentifier || null,
    [FIDELITY_ID_URL_PARAM]: fidelityCardId,

    [IS_FROM_KIOSK_QR_URL_PARAM]: 'true',

    utm_campaign: 'kiosk',
    utm_content: selectServiceProductCategory(state),
    utm_medium: 'qr-code',
    utm_source: selectRetailerLocationSlug(state),
    utm_term: RouteUtils.getPage(),
    ...(userCharacterIdentifier && { [CHARACTERS_URL_PARAM]: userCharacterIdentifier }),
    ...(fidelityCardId && { [FIDELITY_ID_URL_PARAM]: fidelityCardId }),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  qrCodeUrl.search = `${search ? `${search}&` : ''}${queryString}`;
  return qrCodeUrl.toString();
};

export const promotionsFilter = (promotions: TProductLocationPromotion[] = [], promotionsSlugsToFilter: string[]) =>
  promotions?.filter(promotionData => promotionsSlugsToFilter.includes(promotionData?.typeSlug)) || [];

export const migrateAppSliceToServiceSlice = () => {
  const legacyKey = 'persist:app';
  const newKey = 'persist:service';

  const migrateState = () => {
    const legacyState = localStorage.getItem(legacyKey);
    if (legacyState) {
      localStorage.setItem(newKey, legacyState);
      localStorage.removeItem(legacyKey);
    }
  };

  migrateState();
};

export const createTasteJourneyData = ({
  tastePathData,
  feedbackData,
  currentTastePathCategoryId,
  productCategory,
  locale,
  retailerMaxLengthTastePathPerCategory,
}: {
  tastePathData: Record<string, ITastePathData>;
  feedbackData: TProductFeedback[];
  currentTastePathCategoryId: string;
  productCategory: string;
  locale: string;
  retailerMaxLengthTastePathPerCategory: number;
}): TJourneyStep[] => {
  return Object.entries(tastePathData).map(([tastePathCategoryId, tasteData]) => {
    const isDone = feedbackData.filter(
      product => product?.product?.character?.type?.identifier === tastePathCategoryId,
    ).length;

    const currentTastePathCategoryIdProductsLength = tasteData?.products.length;
    const maxSubCategoryLength =
      currentTastePathCategoryIdProductsLength >= retailerMaxLengthTastePathPerCategory
        ? retailerMaxLengthTastePathPerCategory
        : currentTastePathCategoryIdProductsLength;

    const category = TASTE_PATH_PRODUCT_STYLES[productCategory][tastePathCategoryId][`${STYLE_NAME}${locale}`];

    const subCategory = Array.from({ length: maxSubCategoryLength }, (_, i) => ({
      id: i + 1,
      isActive: currentTastePathCategoryId === tastePathCategoryId && i + 1 === isDone + 1,
      isDone: i + 1 <= isDone,
    }));

    return (
      currentTastePathCategoryIdProductsLength && {
        category,
        subCategory,
      }
    );
  });
};

export const testConnectionQuality = async () => {
  const loadingTimeFlag = 300;
  return new Promise<keyof typeof SLOW_PAGE_SCENARIOS>(resolve => {
    const image = new Image();
    const startTime = new Date().getTime();
    let isResolved = false;

    const timeout = setTimeout(() => {
      if (!isResolved) {
        resolve(SLOW_PAGE_SCENARIOS.isLowSpeedConnection);
        isResolved = true;
      }
    }, loadingTimeFlag);

    image.onload = () => {
      const elapsed = new Date().getTime() - startTime;
      console.warn('Elapsed time:', elapsed);
      if (!isResolved) {
        clearTimeout(timeout);
        isResolved = true;
        if (elapsed > loadingTimeFlag) {
          resolve(SLOW_PAGE_SCENARIOS.isLowSpeedConnection);
        } else {
          resolve(SLOW_PAGE_SCENARIOS.isLowSpeedAPI);
        }
      }
    };

    image.onerror = () => {
      if (!isResolved) {
        clearTimeout(timeout);
        isResolved = true;
        resolve(SLOW_PAGE_SCENARIOS.isLowSpeedConnection);
      }
    };

    image.src = `https://media.vinhood.com/media/logos/taste-experience-logo.png?cacheBust=${new Date().getTime()}`;
  });
};
