import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { StyledText } from '@components/web/src/foundations/Text/Text.styles';
import {
  TFontFamilies,
  TFontStyle,
  TStyleWeight,
  TTextAlight,
  TTextDecoration,
  TTextTransform,
  TTypographyVariants,
} from '@components/web/src/foundations/Text/types';

export interface ITextProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  text?: ILocaleText;
  color?: string;
  shouldRenderEmptySelector?: boolean;
  size?: TTypographyVariants;
  weight?: TStyleWeight;
  disableMarkdown?: boolean;
  fontFamily?: TFontFamilies;
  localeOptions?: Record<string, string>;
  localeIndex?: Record<string, string>;
  localeVariables?: Record<string, { readonly id: string; readonly defaultMessage: string }>;
  transformText?: 'default' | 'none' | 'lowercase';
  linesLimit?: number;
  textDecoration?: TTextDecoration;
  fontStyle?: TFontStyle;
  children?: ReactNode;
  textAlign?: TTextAlight;
  textTransform?: TTextTransform;
  handleClick?: (e) => void;
}

export const Text = ({
  text,
  fontFamily = 'Montserrat',
  size = 'body3',
  weight = 'normal',
  color,
  shouldRenderEmptySelector = true,
  localeOptions,
  localeIndex,
  localeVariables,
  transformText = 'default',
  fontStyle = 'normal',
  linesLimit,
  textDecoration = 'none',
  children,
  textAlign = 'unset',
  textTransform = 'none',
  handleClick,
  ...rest
}: ITextProps) => {
  if (!shouldRenderEmptySelector) return null;
  return (
    <StyledText
      $color={color}
      $fontFamily={fontFamily}
      $fontStyle={fontStyle}
      $linesLimit={linesLimit}
      $size={size}
      $textAlign={textAlign}
      $textDecoration={textDecoration}
      $textTransform={textTransform}
      $weight={weight}
      tabIndex={handleClick && 0}
      onClick={handleClick && handleClick}
      onKeyUp={handleClick && handleClick}
      {...rest}
    >
      {!!text && (
        <LocaleFragment
          index={localeIndex}
          message={text}
          options={localeOptions}
          transformText={transformText}
          variables={localeVariables}
        />
      )}
      {children}
    </StyledText>
  );
};
