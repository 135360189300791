import { localeCommon } from '@lib/tools/locale/source/web/common';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import { SLOW_PAGE_SCENARIOS } from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import * as S from '@components/web/src/templates/Loading/LowSpeedWarning/styles';

interface IProps {
  slowPageScenario: keyof typeof SLOW_PAGE_SCENARIOS;
}

export const LowSpeedWarning = ({ slowPageScenario }: IProps) => {
  const { lowSpeedWarningTitle, lowSpeedWarningDescription, slowApiWarningDescription, slowApiWarningTitle } =
    localeCommon.lowSpeedWarning;

  return (
    <S.LowSpeedWarningContainer>
      {slowPageScenario === SLOW_PAGE_SCENARIOS.isLowSpeedConnection && (
        <>
          <Text color={STATIC_COLORS.base.white} size="subtitle2" text={lowSpeedWarningTitle} weight="bold" />
          <Text color={STATIC_COLORS.base.white} size="body1" text={lowSpeedWarningDescription} />
        </>
      )}
      {slowPageScenario === SLOW_PAGE_SCENARIOS.isLowSpeedAPI && (
        <>
          <Text color={STATIC_COLORS.base.white} size="subtitle2" text={slowApiWarningTitle} weight="bold" />
          <Text
            color={STATIC_COLORS.base.white}
            size="body1"
            text={slowApiWarningDescription}
            transformText="lowercase"
          />
        </>
      )}
    </S.LowSpeedWarningContainer>
  );
};
