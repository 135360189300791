import { FC, useLayoutEffect, useRef, useState } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { TMPThirdPartyChoiceClick } from '@lib/tools/dat/mixpanel/types';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import Button from '@components/web/src/atoms/Buttons/Button';
import Confetti from '@components/web/src/atoms/Confetti/Confetti';
import {
  DefaultThirdPartyContent,
  TastePathThirdPartyContent,
  ThirdPartyConnector,
} from '@components/web/src/templates/ThirdPartyConsentPage/components';
import {
  TThirdPartyConsentVariant,
  ThirdPartyConsentVariants,
} from '@components/web/src/templates/ThirdPartyConsentPage/components/ThirdPartyConnector';
import * as S from '@components/web/src/templates/ThirdPartyConsentPage/styles';

interface IProps {
  characterName: string;
  productCategory: TProductCategory;
  parsedProductInstanceData: TParsedProductInstance;
  thirdPartyData: IParsedServiceTerm;
  retailerName: string;
  variant?: TThirdPartyConsentVariant;
  isShowThirdPartyAcceptance: boolean;
  navigateToCatalog: () => void;
  handleThirdPartyClick: (optionSelected: TMPThirdPartyChoiceClick) => void;
}

export const ThirdPartyConsentPage: FC<IProps> = ({
  characterName,
  productCategory,
  parsedProductInstanceData,
  thirdPartyData,
  variant = ThirdPartyConsentVariants.Default,
  retailerName,
  isShowThirdPartyAcceptance,
  navigateToCatalog,
  handleThirdPartyClick,
}) => {
  const { continueBtn } = localeWidget.thirdPartyConsentPage;

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentWrapperHeight, setContentWrapperHeight] = useState(0);
  const [startAnimation, setStartAnimation] = useState(true);

  useLayoutEffect(() => {
    // to hide confetti animation
    const timer = setTimeout(() => setStartAnimation(null), 6500);

    return () => {
      clearTimeout(timer);
      setStartAnimation(null);
    };
  }, [startAnimation]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentWrapperHeight(contentRef.current.offsetHeight);
    }
  }, []);

  return (
    <S.ThirdPartyConsentPageContainer isFullHeight isFullWidth direction="column" gap={0}>
      {startAnimation && <Confetti />}
      <S.ContentWrapper gap={0} justify="center">
        {variant === ThirdPartyConsentVariants.TastePath ? (
          <TastePathThirdPartyContent characterName={characterName} contentRef={contentRef} />
        ) : (
          <DefaultThirdPartyContent
            contentRef={contentRef}
            parsedProductInstanceData={parsedProductInstanceData}
            productCategory={productCategory}
          />
        )}
      </S.ContentWrapper>
      {isShowThirdPartyAcceptance ? (
        <ThirdPartyConnector
          contentWrapperHeight={contentWrapperHeight}
          handleThirdPartyClick={handleThirdPartyClick}
          retailerName={retailerName}
          thirdPartyData={thirdPartyData}
          variant={variant}
        />
      ) : (
        <S.ContinueButtonWrapper>
          <Button
            isFullWidth
            fontSize="subtitle2"
            handleClick={navigateToCatalog}
            size="md"
            text={continueBtn}
            textWeight="medium"
          />
        </S.ContinueButtonWrapper>
      )}
    </S.ThirdPartyConsentPageContainer>
  );
};
