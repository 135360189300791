import styled from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const EmailRegistrationFormContainer = styled(Flexbox)`
  width: 100%;

  .auth-form-server-error {
    margin-top: 8px;
  }
`;

export const LinkButton = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

export const LinkText = styled(Text)`
  text-decoration-line: underline;
  cursor: pointer;
`;
