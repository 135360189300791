import { FC } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { PRODUCT_CARD_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import * as S from '@components/web/src/organisms/TestResult/ProductSection/styles';

interface IProps {
  storeType: TRetailerLocationStoreType;
  parsedProductInstanceData: TParsedProductInstance;
  productCategory: TProductCategory;
  retailerLocationName?: string;
  isLoadingProduct: boolean;
  shouldHideComment: boolean;
  shouldHideFeedback: boolean;
  shouldHideWishlist: boolean;
  handleProductCardClick?: () => void;
}

const ProductSection: FC<IProps> = ({
  storeType,
  parsedProductInstanceData,
  productCategory,
  retailerLocationName,
  isLoadingProduct,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  handleProductCardClick,
}) => {
  const { productSectionTitle } = localeWidget.testResultPage;

  const { productCategories } = localeCommon;

  return (
    <S.ProductsSection>
      <S.AnimatedWrapper>
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          localeIndex={{ productCategory }}
          localeOptions={{ retailerLocationName }}
          localeVariables={{ productCategoryText: productCategories[productCategory] }}
          size="h5"
          text={productSectionTitle}
          textAlign="center"
          weight="semibold"
        />
      </S.AnimatedWrapper>
      <S.ProductCardWrapper onClick={handleProductCardClick}>
        <ProductCard
          isProductCardClickDisabled
          isResponsive
          isLoading={isLoadingProduct}
          mixpanelPositionContext={MP_POSITION_CONTEXT.TEST_RESULT_PAGE}
          parsedProductInstanceData={parsedProductInstanceData}
          shouldHideComment={shouldHideComment}
          shouldHideFeedback={shouldHideFeedback}
          shouldHideWishlist={shouldHideWishlist}
          storeType={storeType}
          variant={PRODUCT_CARD_VARIANTS.COMPACT}
        />
      </S.ProductCardWrapper>
    </S.ProductsSection>
  );
};

export default ProductSection;
