import React, { FC } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { IB2CArticle } from '@app/native/src/interfaces/article';

import { TCharacter } from '@lib/core/characters/types';
import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { IFetchProductsOptions } from '@lib/tools/productsSwiper/types/interface';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import HomePageSkeleton from '@components/web/src/app/Skeleton/HomePageSkeleton';
import SkeletonWrapper from '@components/web/src/app/Skeleton/SkeletonWrapper';
import ProductsSwiper from '@components/web/src/app/Swiper/ProductsSwiper';
import qrIcon from '@components/web/src/assets/legacy/b2c/icons/qr_icon.svg';
import locationIcon from '@components/web/src/assets/legacy/b2c/location_icon.png';
import BannerCard, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import Button from '@components/web/src/atoms/Buttons/Button';
import SectionHeader from '@components/web/src/atoms/Headers/SectionHeader/SectionHeader';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import ArticleCard from '@components/web/src/organisms/Cards/ArticleCard/ArticleCard';
import MyTasteProfileCard from '@components/web/src/organisms/Cards/MyTasteProfileCard/MyTasteProfileCard';
import SituationalCard from '@components/web/src/organisms/Cards/SituationalCard/SituationalCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import QrScannerModal from '@components/web/src/templates/Modals/QrScannerModal/QrScannerModal';

interface IProps {
  articleData: IB2CArticle;
  isLoading?: boolean;
  profilePercent?: number;
  userBestMatchCharacterByProductCategory: Record<TProductCategory, TCharacter>;
  shouldDisplayEventSection: boolean;
  storeType: TRetailerLocationStoreType;
  retailerTags: Record<string, any>;
  hintBanner: TBannerCard[];
  situationalCardsData: string[];
  isQrScannerModalOpen: boolean;
  isUserHasAnyCharacter: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  isTagsDataCompleted: boolean;
  navigate: NavigateFunction;
  navigateToEdutainmentList: () => void;
  onCharacterItemSectionClick: () => void;
  navigateToSettingsPage: () => void;
  navigateToHintPage: () => void;
  navigateToArticlesCatalog: () => void;
  navigateToArticlePage: (articleId: string) => void;
  navigateToTagsPage: () => void;
  handleEventClick: (link: string) => void;
  setIsQrScannerModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleFetchSwiperProducts: (requestParams: IFetchProductsOptions) => void;
  allProducts: TParsedProductInstance[];
  isProductsLoading: boolean;
  navigateToFoodHabitsPage: () => void;
}

const HomePage: FC<IProps> = ({
  handleFetchSwiperProducts,
  articleData,
  isLoading,
  profilePercent = 90,
  userBestMatchCharacterByProductCategory,
  storeType,
  shouldDisplayEventSection,
  retailerTags,
  hintBanner,
  situationalCardsData,
  isQrScannerModalOpen,
  isUserHasAnyCharacter,
  discoveryQuiz,
  isTagsDataCompleted,
  navigate,
  setIsQrScannerModalOpen,
  handleEventClick,
  navigateToEdutainmentList,
  onCharacterItemSectionClick,
  navigateToHintPage,
  navigateToSettingsPage,
  navigateToArticlesCatalog,
  navigateToTagsPage,
  navigateToArticlePage,
  allProducts,
  isProductsLoading,
  navigateToFoodHabitsPage,
}) => {
  const { homePage, b2cEvents, hintPage, hintSection } = localeApp;

  return (
    <SkeletonWrapper showSkeleton={isLoading} skeleton={HomePageSkeleton}>
      <div className="app-home-page-container">
        <section className="user-characters-info-section">
          <MyTasteProfileCard
            progress={profilePercent}
            userBestMatchCharacterByProductCategory={userBestMatchCharacterByProductCategory}
            onCharacterItemSectionClick={onCharacterItemSectionClick}
            onPersonalizeSectionClick={isTagsDataCompleted ? navigateToSettingsPage : navigateToTagsPage}
          />
        </section>

        <div className="home-page-content">
          <section className="qr-scanner-section">
            <SectionHeader subtitle={homePage.locationSubtitleText} title={homePage.locationTitleText} />
            <button className="qr-scanner-button" type="button" onClick={() => setIsQrScannerModalOpen(true)}>
              <img alt="location" src={locationIcon} />
              <span>
                <LocaleFragment message={homePage.locationScanCodeText} />
              </span>
              <img alt="qr" src={qrIcon} />
            </button>
            {isQrScannerModalOpen && (
              <QrScannerModal hideModal={() => setIsQrScannerModalOpen(false)} isModalOpen={isQrScannerModalOpen} />
            )}
          </section>

          <section className="situational-cards-section">
            <SectionHeader
              subtitle={homePage.characterCardSituationalSubtitleText}
              title={homePage.characterCardSituationalText}
            />
            <SwiperHOC>
              {situationalCardsData.map(cardProductCategory => (
                <SituationalCard key={cardProductCategory} navigate={navigate} productCategory={cardProductCategory} />
              ))}
            </SwiperHOC>
          </section>
          <section className="vinhood-academy-section">
            <SectionHeader subtitle={homePage.vinhoodAcademySubtitle} title={homePage.vinhoodAcademyTitle} />
            <Button
              className="cta-btn"
              iconPosition="right"
              iconVariant="arrow"
              text={homePage.vinhoodAcademyBtn}
              variant={VH_VARIANTS.LINK}
              onClick={navigateToEdutainmentList}
            />
          </section>
          {shouldDisplayEventSection && (
            <section className="event-section">
              <SectionHeader subtitle={b2cEvents.eventHomePageDescription} title={b2cEvents.eventHomePageTitle} />
              <img
                aria-hidden
                alt="event description"
                className="event-image"
                src={retailerTags?.homeImage}
                onClick={() => handleEventClick(retailerTags?.homeLink)}
              />
              <Button
                className="cta-btn"
                iconPosition="right"
                iconVariant="arrow"
                text={b2cEvents.eventCTAText}
                variant={VH_VARIANTS.LINK}
                onClick={() => handleEventClick(retailerTags?.homeLink)}
              />
            </section>
          )}
          {isUserHasAnyCharacter && (
            <section className="hint-section">
              <SectionHeader subtitle={hintPage.bannerSectionDescText} title={hintPage.bannerSectionHeadingText} />
              {hintBanner?.map((banner, idx) => <BannerCard key={idx} {...banner} bgColor="white" theme="light" />)}
              <Button
                className="cta-btn"
                iconPosition="right"
                iconVariant="arrow"
                text={hintSection.hintCTAText}
                variant={VH_VARIANTS.LINK}
                onClick={navigateToHintPage}
              />
            </section>
          )}
          <section className="home-products-section">
            <SectionHeader subtitle={homePage.productSectionDescription} title={homePage.productSectionTitle} />
            <ProductsSwiper
              shouldHideComment
              allProducts={allProducts}
              discoveryQuiz={discoveryQuiz}
              handleFetchSwiperProducts={handleFetchSwiperProducts}
              isProductsLoading={isProductsLoading}
              navigateToFoodHabitsPage={navigateToFoodHabitsPage}
              storeType={storeType}
            />
          </section>
          {!!articleData && (
            <section className="home-articles-section">
              <SectionHeader title={homePage.articleSwiperText} />
              <ArticleCard isResponsive articleData={articleData} navigateToArticlePage={navigateToArticlePage} />
              <Button
                className="cta-btn"
                iconPosition="right"
                iconVariant="arrow"
                text={homePage.articleBtn}
                variant={VH_VARIANTS.LINK}
                onClick={navigateToArticlesCatalog}
              />
            </section>
          )}
        </div>
      </div>
    </SkeletonWrapper>
  );
};

export default HomePage;
