import { FC, useEffect, useState } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import ProductListComponent from '@components/web/src/components/Catalog/ProductList/ProductList';
import { STATIC_COLORS } from '@components/web/src/foundations';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import * as S from '@components/web/src/pages/widget/Catalog/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import SeeAllProductsButton from '@components/web/src/widget/Buttons/SeeAllProductsButton';
import MatchedProducts from '@components/web/src/widget/Catalog/MatchedProducts';

export interface ILimitedCatalogProps {
  products: TParsedProductInstance[];
  matchedProducts: TParsedProductInstance[];
  retailerName?: string;
  catalogType: string;
  shouldShowDownloadAppCard?: boolean;
  isProductsLoaded: boolean;
  isProductsRequestLoading?: boolean;
  productCategory: TProductCategory;
  storeType: TRetailerLocationStoreType;
  discoveryQuiz: IDiscoveryQuiz;
  isShowThirdPartyAcceptance: boolean;
  shouldHideComment: boolean;
  shouldHideFeedback: boolean;
  shouldHideWishlist: boolean;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
  handleThirdPartyAcceptance: (value: boolean) => void;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
}

const LimitedCatalog: FC<ILimitedCatalogProps> = ({
  products,
  isProductsLoaded,
  matchedProducts,
  isProductsRequestLoading,
  retailerName,
  catalogType,
  productCategory = PRODUCT_CATEGORY_WINE,
  storeType,
  shouldShowDownloadAppCard,
  discoveryQuiz,
  isShowThirdPartyAcceptance,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  thirdPartyNewsletterTexts,
  handleThirdPartyAcceptance,
  handleProductsRequest,
}) => {
  const [isProductsListOpened, setIsProductsListOpened] = useState(false);

  const handleToggleProductsList = () => {
    setIsProductsListOpened(!isProductsListOpened);
  };

  useEffect(() => {
    if (isProductsLoaded && !matchedProducts.length) {
      setIsProductsListOpened(true);
    }
  }, [isProductsLoaded, matchedProducts]);

  const { productCatalog: productCatalogMessagesWidget } = localeWidget;

  const isMatchedProductsAvailable = !!matchedProducts.length;
  const isProductsAvailable = !!products.length;
  const isBoldProductsListHeadText = !isMatchedProductsAvailable;
  const noProductsAvailable = !isProductsAvailable && !isMatchedProductsAvailable && isProductsLoaded;

  const isShowSeeAllProductsButton = isMatchedProductsAvailable && isProductsAvailable;
  return (
    <S.ProductCatalogWrapper>
      {noProductsAvailable && <NoProductsAvailable />}

      <>
        {(isProductsRequestLoading || isMatchedProductsAvailable) && (
          <>
            <MatchedProducts
              catalogType={catalogType}
              discoveryQuiz={discoveryQuiz}
              handleProductsRequest={handleProductsRequest}
              isProductsRequestLoading={isProductsRequestLoading}
              matchedProducts={matchedProducts}
              productCategory={productCategory}
              shouldHideComment={shouldHideComment}
              shouldHideFeedback={shouldHideFeedback}
              shouldHideWishlist={shouldHideWishlist}
              storeType={storeType}
            />
            {isShowThirdPartyAcceptance && (
              <ThirdPartyNewsletter
                handleThirdPartyAcceptance={handleThirdPartyAcceptance}
                retailerName={retailerName}
                thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
                withMarginTop={shouldShowDownloadAppCard}
              />
            )}
          </>
        )}
        {isShowSeeAllProductsButton && (
          <SeeAllProductsButton handleClick={handleToggleProductsList} isOpenedState={isProductsListOpened} />
        )}
        {isProductsListOpened && isProductsAvailable && (
          <>
            <S.StyledText
              $isBoldProductsListHeadText={isBoldProductsListHeadText}
              color={STATIC_COLORS.base.black}
              fontFamily={isBoldProductsListHeadText ? 'Fraunces' : 'Montserrat'}
              localeIndex={{ productCategory }}
              size={isBoldProductsListHeadText ? 'h5' : 'body1'}
              text={productCatalogMessagesWidget.allProductsByProximity}
              weight="semibold"
            />

            <ProductListComponent
              disablePagination
              discoveryQuiz={discoveryQuiz}
              handleProductsRequest={handleProductsRequest}
              handleThirdPartyAcceptance={handleThirdPartyAcceptance}
              isMatchedProductsAvailable={isMatchedProductsAvailable}
              isProductsRequestLoading={!isProductsLoaded}
              isShowThirdPartyAcceptance={isShowThirdPartyAcceptance}
              itemsCount={products.length}
              products={products}
              retailerName={retailerName}
              shouldHideComment={shouldHideComment}
              shouldHideFeedback={shouldHideFeedback}
              shouldHideWishlist={shouldHideWishlist}
              shouldShowDownloadAppCard={shouldShowDownloadAppCard}
              storeType={storeType}
              thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
            />
          </>
        )}
      </>
    </S.ProductCatalogWrapper>
  );
};

export default LimitedCatalog;
