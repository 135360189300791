import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ACTIVE_PANEL_FILTER_URL_PARAM } from '@app/web/src/helpers/consts';

import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { TJournalProductPanelFilter } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import {
  FORMAT_QUERY,
  IS_FROM_PRODUCT_DETAILS_PAGE,
  ORIGINS_QUERY,
  PRODUCT_CHARACTERISTICS_QUERY,
  PRODUCT_NAME_QUERY,
  SEARCH_BY_NAME_FIELDS_QUERY,
  STYLE_QUERY,
} from '@lib/core/service/consts';
import { useScrollToTop } from '@lib/core/service/hooks/useScrollToTop';
import { prependBasename } from '@lib/core/service/utils';
import { useProductList } from '@lib/core/users/hooks';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import { useProductCatalog } from '@lib/tools/productCatalog/hooks/useProductCatalog';
import { actionFetchProductsListsExtended, resetProductCatalogState } from '@lib/tools/productCatalog/slices';
import { removeUrlParams, setUrlParams } from '@lib/tools/shared/helpers';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_ITEM_NAME,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  JOURNAL_PRODUCT_VARIANT,
  OS_PRODUCT_NAME_QUERY,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_CATEGORY_URL_PARAM,
  PRODUCT_IDENTIFIER_QUERY,
  TJournalPlacesPanelFilter,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import JournalPage from '@components/web/src/templates/TasteId/TasteIdJournalPage/TasteIdJournalPage';

const JournalProductsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { retailerStoreType } = useRetailerLocation();
  const [isChangedActivePanel, setIsChangedActivePanel] = useState(false);

  const productCategoryUrlParam = searchParams.get(PRODUCT_CATEGORY_URL_PARAM);
  const isFromProductDetailsPage = searchParams.get(IS_FROM_PRODUCT_DETAILS_PAGE) === 'true';
  const activePanelFilterFromUrl = searchParams.get(ACTIVE_PANEL_FILTER_URL_PARAM);

  const [activeJournalPanelFilter, setActivePanelFilter] = useState<TJournalProductPanelFilter>(
    (activePanelFilterFromUrl as TJournalProductPanelFilter) || FILTER_TYPE_SHOW_FAVORITES,
  );
  useScrollToTop(activeJournalPanelFilter);

  const handleProductPanelFilterChange = (value: TJournalProductPanelFilter) => {
    setActivePanelFilter(value);
    setUrlParams([{ key: ACTIVE_PANEL_FILTER_URL_PARAM, value }]);
  };

  const handlePanelFilterChange = (value: TJournalProductPanelFilter | TJournalPlacesPanelFilter) => {
    handleProductPanelFilterChange(value as TJournalProductPanelFilter);

    const scrollElement = document.querySelector<HTMLElement>('.infinite-scroll-component');

    if (scrollElement && scrollElement.parentElement) {
      scrollElement.parentElement.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });
    }
    setIsChangedActivePanel(true);
  };

  const { isProductsRequestLoading, products, itemsCount } = useProductCatalog();

  const {
    [FILTER_TYPE_CHARACTERISTICS]: selectedCharacteristicsQuery,
    [FILTER_TYPE_FORMAT]: selectedFormatsQuery,
    [FILTER_TYPE_ORIGIN]: selectedOriginsQuery,
    [FILTER_TYPE_STYLE]: selectedStylesQuery,
    [FILTER_TYPE_ITEM_NAME]: searchTextValue,
    [FILTER_TYPE_SHOW_FAVORITES]: selectedWishlistProductsQuery,
    [FILTER_TYPE_RATING]: selectedRatedProductsQuery,
    isFilterOpened,
  } = useCatalogFilter({ activeJournalPanelFilter });

  const { wishlistProductInstanceIds, isWishlistProductListLoading } = useProductList();

  const { discoveryQuiz } = useDiscoveryQuizData();

  useEffect(() => {
    if (!isFromProductDetailsPage) {
      dispatch(actionResetCatalogFilters());
      dispatch(resetProductCatalogState());
    }

    if (isFromProductDetailsPage) {
      removeUrlParams({
        keys: [IS_FROM_PRODUCT_DETAILS_PAGE, ACTIVE_PANEL_FILTER_URL_PARAM],
      });
    }
    return () => {
      dispatch(resetProductCatalogState());
    };
  }, []);

  const handleBackBtnClick = () =>
    navigate(prependBasename(PAGES.vinhood.tasteId.tasteId, { [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam }));

  const handleProductsRequest = ({ isPagination = false, isJournalCase = false }: IHandleProductsRequestParams) => {
    return dispatch(
      actionFetchProductsListsExtended({
        isJournalCase,
        isPagination,
        params: {
          [PRODUCT_CATEGORY_QUERY]: productCategoryUrlParam,
          ...(searchTextValue && {
            [PRODUCT_NAME_QUERY]: `*${searchTextValue}*`,
            [SEARCH_BY_NAME_FIELDS_QUERY]: OS_PRODUCT_NAME_QUERY,
          }),
          ...(selectedOriginsQuery && { [ORIGINS_QUERY]: selectedOriginsQuery }),
          ...(selectedFormatsQuery && { [FORMAT_QUERY]: selectedFormatsQuery }),
          ...(selectedStylesQuery && { [STYLE_QUERY]: selectedStylesQuery }),
          ...(selectedCharacteristicsQuery && {
            [PRODUCT_CHARACTERISTICS_QUERY]: selectedCharacteristicsQuery,
          }),
          ...(selectedWishlistProductsQuery && {
            [PRODUCT_IDENTIFIER_QUERY]:
              activeJournalPanelFilter === FILTER_TYPE_RATING
                ? selectedRatedProductsQuery
                : selectedWishlistProductsQuery,
          }),
        },
      }),
    );
  };

  const navigateToCatalogPage = () => navigate(prependBasename(PAGES.vinhood.catalog));

  useEffect(() => {
    handleProductsRequest({ isJournalCase: true });
  }, [activeJournalPanelFilter, wishlistProductInstanceIds]);

  useEffect(() => {
    if (!isProductsRequestLoading && !isWishlistProductListLoading) {
      setIsChangedActivePanel(false);
    }
  }, [isProductsRequestLoading, isWishlistProductListLoading]);

  const isAllProductsLoaded = itemsCount === products.length;
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { parseProductsArray } = useParsedProducts();

  return (
    <JournalPage
      activeJournalPanelFilter={activeJournalPanelFilter}
      discoveryQuiz={discoveryQuiz}
      handleBackBtnClick={handleBackBtnClick}
      handlePanelFilterChange={handlePanelFilterChange}
      handleProductsRequest={handleProductsRequest}
      isAllProductsLoaded={isAllProductsLoaded}
      isFilterOpened={isFilterOpened}
      isItemsLoading={isChangedActivePanel}
      items={parseProductsArray(products)}
      itemsCount={itemsCount}
      navigateToCatalogPage={navigateToCatalogPage}
      productCategory={productCategoryUrlParam}
      shouldHideComment={isDesignSetVinhoodApp}
      storeType={retailerStoreType}
      variant={JOURNAL_PRODUCT_VARIANT}
    />
  );
};

export default JournalProductsContainer;
