import { FC, useState } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { TProductCatalogOrdering } from '@lib/tools/filterManager/slices/productFilter';
import FilterContainer from '@lib/tools/filterManager/views/containers/FilterContainer';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';
import { IFilterProps, IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import ProductListComponent from '@components/web/src/components/Catalog/ProductList/ProductList';
import SortingSection from '@components/web/src/components/Catalog/SortingSection/SortingSection';
import { STATIC_COLORS } from '@components/web/src/foundations';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import * as S from '@components/web/src/pages/widget/Catalog/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import SeeAllProductsButton from '@components/web/src/widget/Buttons/SeeAllProductsButton';
import MatchedProducts from '@components/web/src/widget/Catalog/MatchedProducts';

export interface IExtendedCatalogProps {
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  products: TParsedProductInstance[];
  matchedProducts: TParsedProductInstance[];
  catalogType: string;
  retailerName?: string;
  isProductsLoaded: boolean;
  isProductsRequestLoading: boolean;
  shouldShowDownloadAppCard?: boolean;
  productCategory: TProductCategory;
  filterProps?: IFilterProps;
  itemsCount?: number;
  storeType: TRetailerLocationStoreType;
  withOpenedProductList?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  isChangeOrderingEnabled?: boolean;
  activeOrdering?: TProductCatalogOrdering;
  isShowThirdPartyAcceptance: boolean;
  shouldHideComment: boolean;
  shouldHideFeedback: boolean;
  shouldHideWishlist: boolean;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
  handleThirdPartyAcceptance: (value: boolean) => void;
  handleChangeOrdering?: (order: TProductCatalogOrdering) => void;
}

const ExtendedCatalog: FC<IExtendedCatalogProps> = ({
  thirdPartyNewsletterTexts,
  products,
  isProductsLoaded,
  matchedProducts,
  catalogType,
  storeType,
  retailerName,
  productCategory = PRODUCT_CATEGORY_WINE,
  itemsCount,
  shouldShowDownloadAppCard,
  filterProps,
  isProductsRequestLoading,
  withOpenedProductList = false,
  discoveryQuiz,
  activeOrdering,
  isChangeOrderingEnabled = false,
  isShowThirdPartyAcceptance,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  handleThirdPartyAcceptance,
  handleChangeOrdering,
  handleProductsRequest,
}) => {
  const { isFilterOpened } = useCatalogFilter();
  const [isProductsListOpened, setIsProductsListOpened] = useState(withOpenedProductList);
  const isMatchedProductsAvailable = !!matchedProducts.length;
  const isProductsAvailable = !!products.length;
  const noProductsAvailable =
    !isProductsAvailable && !isMatchedProductsAvailable && isProductsLoaded && !isProductsRequestLoading;

  const handleToggleProductsList = () => {
    setIsProductsListOpened(!isProductsListOpened);
  };

  const { productCatalog: productCatalogMessagesWidget } = localeWidget;
  const isShowSeeAllProductsButton = isMatchedProductsAvailable && isProductsAvailable;

  return (
    <S.ProductCatalogWrapper $isFilterOpened={isFilterOpened}>
      {noProductsAvailable && <NoProductsAvailable />}
      {!isFilterOpened && (
        <>
          {(isProductsRequestLoading || isMatchedProductsAvailable) && (
            <>
              <MatchedProducts
                catalogType={catalogType}
                discoveryQuiz={discoveryQuiz}
                handleProductsRequest={handleProductsRequest}
                isProductsRequestLoading={!isProductsLoaded}
                matchedProducts={matchedProducts}
                productCategory={productCategory}
                shouldHideComment={shouldHideComment}
                shouldHideFeedback={shouldHideFeedback}
                shouldHideWishlist={shouldHideWishlist}
                storeType={storeType}
              />
              {isShowThirdPartyAcceptance && (
                <ThirdPartyNewsletter
                  handleThirdPartyAcceptance={handleThirdPartyAcceptance}
                  retailerName={retailerName}
                  thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
                  withMarginTop={shouldShowDownloadAppCard}
                />
              )}
            </>
          )}

          {isShowSeeAllProductsButton && (
            <SeeAllProductsButton handleClick={handleToggleProductsList} isOpenedState={isProductsListOpened} />
          )}
          {isProductsListOpened && isProductsAvailable && (
            <S.StyledText
              color={STATIC_COLORS.base.black}
              size="body1"
              text={productCatalogMessagesWidget.soManyProducts}
              weight="semibold"
            />
          )}
        </>
      )}

      <FilterContainer
        {...filterProps}
        isSearchEnabled
        handleApply={handleProductsRequest}
        isLayoutHidden={!isProductsListOpened || isProductsRequestLoading}
        productCategory={productCategory}
      />

      {!isFilterOpened && isProductsListOpened && (
        <>
          <SortingSection
            activeOrdering={activeOrdering}
            handleChangeOrdering={handleChangeOrdering}
            isChangeOrderingEnabled={isChangeOrderingEnabled}
            isProductsRequestLoading={isProductsRequestLoading}
            itemsCount={itemsCount}
          />
          <ProductListComponent
            disablePagination={false}
            discoveryQuiz={discoveryQuiz}
            handleProductsRequest={handleProductsRequest}
            handleThirdPartyAcceptance={handleThirdPartyAcceptance}
            isMatchedProductsAvailable={isMatchedProductsAvailable}
            isProductsRequestLoading={isProductsRequestLoading}
            isShowThirdPartyAcceptance={isShowThirdPartyAcceptance}
            itemsCount={itemsCount}
            products={products}
            retailerName={retailerName}
            shouldHideComment={shouldHideComment}
            shouldHideFeedback={shouldHideFeedback}
            shouldHideWishlist={shouldHideWishlist}
            shouldShowDownloadAppCard={shouldShowDownloadAppCard}
            storeType={storeType}
            thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
          />
        </>
      )}
    </S.ProductCatalogWrapper>
  );
};

export default ExtendedCatalog;
