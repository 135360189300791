import { Dispatch, FC, SetStateAction } from 'react';

import { IComment } from '@lib/core/comments/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import noteIcon from '@components/web/src/assets/icons/comment/NoteIcon.svg';
import noteIconFill from '@components/web/src/assets/icons/comment/NoteIconFill.svg';
import * as S from '@components/web/src/atoms/Comment/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import CommentModal from '@components/web/src/templates/Modals/CommentModal/CommentModal';

interface IProps {
  isCommentsDataLoading: boolean;
  isNewCommentsDataFetching: boolean;
  setIsNewCommentsDataFetching: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment: (comment: string) => void;
  commentFromList: IComment;
  isCommentInList?: boolean;
}

const Comment: FC<IProps> = ({
  isCommentsDataLoading,
  handleSubmitComment,
  isNewCommentsDataFetching,
  setIsNewCommentsDataFetching,
  commentFromList,
  isCommentInList,
}) => {
  const [isCommentModalOpen, { open: openModal, close: closeModal }] = useDisclosure();

  const { note } = localeCommon.comment;

  return (
    <>
      <S.CommentButton onClick={() => openModal()}>
        <Image alt="Comment icon" height="20px" src={isCommentInList ? noteIconFill : noteIcon} width="25px" />
        <Text color={STATIC_COLORS.teal['600']} size="body3" text={note} weight="medium" />
      </S.CommentButton>

      {isCommentModalOpen && (
        <CommentModal
          commentFromList={commentFromList}
          handleSubmitComment={handleSubmitComment}
          isCommentModalOpen={isCommentModalOpen}
          isCommentsDataLoading={isCommentsDataLoading}
          isNewCommentsDataFetching={isNewCommentsDataFetching}
          setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default Comment;
