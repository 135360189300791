import { useSelector } from 'react-redux';

import {
  selectCommentsData,
  selectIsCommentCreating,
  selectIsCommentUpdating,
  selectIsCommentsLoading,
} from '@lib/core/comments/selectors/comments';

export const useComments = () => {
  const commentsList = useSelector(selectCommentsData);
  const isCommentsLoading = useSelector(selectIsCommentsLoading);
  const isCommentUpdating = useSelector(selectIsCommentUpdating);
  const isCommentCreating = useSelector(selectIsCommentCreating);

  return {
    commentsList,
    isCommentCreating,
    isCommentUpdating,
    isCommentsLoading,
  };
};
