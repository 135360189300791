import { css, keyframes, styled } from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

const screenTransition = keyframes`
  0% {
    background: ${STATIC_COLORS.base.white};
  }
  90% {
    background: ${STATIC_COLORS.base.white};
  }
  100% {
    background: ${STATIC_GRADIENTS.primaryGradient};
  }
`;

const showLogo = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const hideLogo = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const fadeInVinhood = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutVinhood = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const showLoadingGIF = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PartnerSplashScreenContainer = styled(Flexbox)<{ $retailerLogo: string }>`
  position: absolute;
  inset: 0;
  z-index: 999999999;
  height: 100vh !important;
  background: ${STATIC_COLORS.base.white};

  ${({ $retailerLogo }) =>
    $retailerLogo
      ? css`
          animation: ${screenTransition} 2s forwards;

          .retailer_logo {
            opacity: 0;
            position: absolute;
            animation:
              ${showLogo} 0.2s forwards 0.5s,
              ${hideLogo} 0.5s forwards 1.7s;
          }

          .vinhood_logo,
          .loading_gif {
            opacity: 0;
          }

          .vinhood_logo {
            position: absolute;
            animation:
              ${fadeInVinhood} 0.5s forwards 2.2s,
              ${fadeOutVinhood} 0.5s forwards 3.7s;
          }

          .loading_gif {
            animation: ${showLoadingGIF} 2s forwards 4.2s;

            img {
              width: 150px;
              height: 150px;
              object-fit: cover;
            }
          }
        `
      : css`
          animation: ${screenTransition} 0s forwards;

          .vinhood_logo {
            position: absolute;
            animation:
              ${fadeInVinhood} 1s forwards 0s,
              ${fadeOutVinhood} 1s forwards 2.5s;
          }

          .loading_gif {
            opacity: 0;
            animation: ${showLoadingGIF} 2s forwards 3.5s;

            img {
              width: 150px;
              height: 150px;
              object-fit: cover;
            }
          }
        `}

  @media (min-width: ${SCREEN_SIZE.vhApp}) {
    width: ${SCREEN_SIZE.vhApp};
    margin: auto;
    height: 86vh !important;
  }
`;

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  background: ${STATIC_COLORS.base.white};

  @media (min-width: ${SCREEN_SIZE.vhApp}) {
    width: 500px;
    margin-inline: auto;
    height: 86vh !important;
  }
`;
